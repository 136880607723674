<template>
  <div :class="classList.container" :name="name">
    <span :class="classList.sidelabel" v-if="sideLabel">
      {{ sideLabel }}
    </span>

    <label v-if="label" :for="id" :class="derivedLabelClass">{{ label }}</label>
    <input
      :id="id"
      :ref="id"
      :class="classList.input"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :name="name"
      v-model="innerValue"
      @input="handleInput($event.target.value)"
      @blur="handleBlur($event.target.value)"
      @keypress="handleKeyPress"
      @focus="handleTextFieldFocus"
    />
    <Balloon :text="message" :arrowPosition="balloonArrowPosition" />
  </div>
</template>

<script>
import {
  InputType,
  BalloonArrowPosition,
  INPUT_CONTAINER_CLASS,
  Key,
  TEXT_FIELD,
  READONLY,
  ON,
  EventType,
  EMPTY_STRING,
} from '@/constants'
import { inputMixin } from '@/mixins'

export default {
  name: TEXT_FIELD,
  inheritAttrs: false,
  components: {
    Balloon: () => import('@/components/Balloon'),
  },
  mixins: [inputMixin],
  props: {
    readonly: Boolean,
    type: {
      type: String,
      default: InputType.TEXT,
    },
    autocomplete: {
      type: String,
      default: ON,
    },
    sideLabel: String,
  },
  computed: {
    classList() {
      return {
        sidelabel: 'csn-textfield-sidelabel',
        input: ['form-control form-control', this.inputClass],
        container: [
          INPUT_CONTAINER_CLASS,
          this.containerClass,
          this.sideLabel ? 'csn-textfield-with-sidelabel' : EMPTY_STRING,
        ],
      }
    },
    balloonArrowPosition: () => BalloonArrowPosition.TOP_LEFT,
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === Key.ENTER) {
        this.$emit('enter')
        event.target.blur()
      }
    },
    handleTextFieldFocus() {
      this.$refs[this.id].removeAttribute(READONLY)
      this.$emit(EventType.FOCUS)
    },
  },
}
</script>
